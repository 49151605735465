<template>
  <div class="order-note">
    <div class="p30">
      <div class="note-top">
        <yd-textarea slot="right" placeholder="请输入订单备注" maxlength="100" v-model="note"></yd-textarea>
      </div>
    </div>

    <div class="save-box">
      <div class="save-btn" @click="saveNote">保存</div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: "feedback",
  data() {
    return {
      note: ''
    }
  },
  computed: {
    ...mapState({
      user_remark: state => state.create_order.note,//备注
    })
  },
  methods: {
    saveNote() {
      this.$store.dispatch('addOrderInfo', { note:this.note});
      this.$router.go(-1);
    },
  },
  mounted() {
    if(this.user_remark){
      this.note = this.user_remark
    }
  }
}
</script>

<style lang="less">
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";


.order-note {

  .p30 {
    padding: 0.3rem;
  }

  .note-top {
    padding: 0.2rem;
    background: @color-white;
    border-radius: 0.16rem;
  }

  .yd-textarea-counter {
    font-size: 0.28rem;
    color: #cccccc;
  }

  .yd-textarea > textarea {
    height: 3rem;
    .sc(0.32rem, #333);
  }

  .yd-textarea > textarea::placeholder {
    .sc(0.32rem, @color-light-grey);
  }

  .save-box {
    .wh(100%, 0.88rem);
    padding: 0 0.24rem;
    border-radius: 0.16rem;
  }

  .save-btn {
    .wh(100%, 100%);
    background: var(--main-color);
    border-radius: 1rem;
    line-height: 0.88rem;
    .sc(0.32rem, #fff);
    text-align: center;
  }
}
</style>
