var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "order-note" }, [
    _c("div", { staticClass: "p30" }, [
      _c(
        "div",
        { staticClass: "note-top" },
        [
          _c("yd-textarea", {
            attrs: {
              slot: "right",
              placeholder: "请输入订单备注",
              maxlength: "100",
            },
            slot: "right",
            model: {
              value: _vm.note,
              callback: function ($$v) {
                _vm.note = $$v
              },
              expression: "note",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "save-box" }, [
      _c("div", { staticClass: "save-btn", on: { click: _vm.saveNote } }, [
        _vm._v("保存"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }